<!-- 银行卡支付 -->
<template>
    <div class="bank-recharge-page">
        <div class="bank-recharge-header">
            <!-- <img @click="back" style="width:16px;height:16px;position:absolute;left:12px;top:11px" src="@/assets/images/my/arr-left.png" alt=""> -->
            {{$t("userPage.text55")}}
        </div>
        <div class="bank-recharge-top">
            <img src="@/assets/images/my/amount-icon.png" alt="">
            <div class="bank-recharge-amount">￥{{query.amount}}</div>
        </div>
        <div class="bank-recharge-box">
            <div class="bank-recharge-title">{{$t("userPage.text56")}}</div>
            <div class="bank-recharge-row">
                <span>{{$t("userPage.text57") + "："}}</span>
                <span>{{query.bankuser}}</span>
                <img @click="_copy(query.bankuser)" src="@/assets/images/my/copy-icon.png" alt="">
            </div>
            <div class="bank-recharge-row">
                <span>{{$t("userPage.text58") + "："}}</span>
                <span>{{query.bankname}}</span>
                <img @click="_copy(query.bankname)" src="@/assets/images/my/copy-icon.png" alt="">
            </div>
            <div class="bank-recharge-row">
                <span>{{$t("userPage.text59") + "："}}</span>
                <span>{{query.cardno}}</span>
                <img @click="_copy(query.cardno)" src="@/assets/images/my/copy-icon.png" alt="">
            </div>
            <div class="bank-recharge-row">
                <span>{{$t("userPage.text60") + "："}}</span>
                <span>{{query.branchname}}</span>
                <img @click="_copy(query.branchname)" src="@/assets/images/my/copy-icon.png" alt="">
            </div>
        </div>
        <div class="bank-recharge-box">
            <div class="bank-recharge-title">{{$t("userPage.text61")}}</div>
            <div class="bank-recharge-row">{{$t("userPage.text62")}}</div>
            <div class="bank-recharge-row">{{$t("userPage.text63")}}</div>
            <div class="bank-recharge-row">{{$t("userPage.text64")}}</div>
            <div class="bank-recharge-row">{{$t("userPage.text65")}}</div>
        </div>
    </div>
</template>

<script>

import { _copy } from '@/core/utils/utils'
export default {
    data () {
        return {
            query: {
                amount: '',
                orderid: '',
                cardno: '',
                bankname: '',
                bankuser: '',
                branchname: ''
            }
        }
    },
    mounted () {
        const str = decodeURIComponent(this.$route.query.query)
        const arr = str.split('&')
        arr.forEach(e => {
            const a = e.split('=')
            this.query[a[0]] = a[1]
        })
    },
    methods: {
        _copy,
        back () {
            this.$router.back()
        },
    }
}
</script>

<style scoped lang="scss">
.bank-recharge-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 750px;
    margin: 0 auto;
    height: 100vh;
    background-color: rgba(247, 247, 247, 1);
    color: #666;
    span-align: center;
    box-shadow: 0 0 4px #e2dddd;
    .bank-recharge-page img {
        cursor: pointer;
    }
    .bank-recharge-title {
        font-weight: bold;
        font-size: 15px;
        color: #000;
        margin-bottom: 10px;
    }
    .bank-recharge-header {
        width: 100%;
        line-height: 40px;
        font-weight: bold;
        font-size: 15px;
        color: #000;
        background-color:#fff;
        position: relative;
        text-align: center;
    }
    .bank-recharge-box {
        width: 94%;
        margin: 10px auto;
        box-shadow: 0 0 4px #e2dddd;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 10px 20px 20px 20px;
        background-color:#fff;
        text-align: left;
    }
    .bank-recharge-row {
        line-height: 26px;
    }
    .bank-recharge-row img {
        width: 16px;
        height: 16px;
        position: relative;
        top: 2px;
        left: 10px;
    }
    .bank-recharge-top {
        padding: 20px 0;
        text-align: center;
    }
    .bank-recharge-amount {
        font-size: 20px;
        font-weight: bold;
        color: rgba(255, 56, 34, 1);
        line-height: 20px;
    }
    .bank-recharge-top img {
        width: 20px;
        height: 20px;
    }
}
</style>